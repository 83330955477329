import React from "react";

export const Services = () => {
return (
	<div className="services">
	<h1>Services</h1>
	</div>
);
};

export const ServicesOne = () => {
return (
	<div className="services">
	<h1>Service1</h1>
	</div>
);
};

export const ServicesTwo = () => {
return (
	<div className="services">
	<h1>Service2</h1>
	</div>
);
};

export const ServicesThree = () => {
return (
	<div className="services">
	<h1> Service3</h1>
	</div>
);
};
