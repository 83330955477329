import React from "react";

export const AboutUs = () => {
return (
	<div >
		<div className="card1">
<h1>World Pep Talk - Ocean of information</h1>

		</div>
		<div><br></br></div>
		<div className="card">
	<h4>"Hey there, champion! I know that life can throw some unexpected curveballs your way, and sometimes it might feel like you're facing an uphill battle. But guess what? You've got the strength within you to conquer those challenges and come out even stronger on the other side.

Think about all the times you've faced difficulties before. You've managed to overcome obstacles that once seemed insurmountable. Each setback was just a stepping stone towards your personal growth and success. You have a track record of resilience and determination, and that's something to be truly proud of.</h4>
	</div>
	<div><br></br></div>
	<div className="card2">
	<h4>It's okay to have moments of doubt or uncertainty. Those are just reminders that you're human. But don't let those moments define you. Instead, let your actions and your attitude speak louder. Believe in yourself and your abilities. You have talents and skills that are unique to you, and the world is waiting for you to share them.

Surround yourself with positivity. Reach out to friends, family, or mentors who uplift and support you. Remember that you're not alone on this journey. When things get tough, take a deep breath, and remind yourself of your goals. Visualize your success, and let that vision drive you forward.</h4>
	</div>
	<div><br></br></div>
	<div className="card">
	<h4>Embrace change and adaptability. The world is constantly evolving, and so are you. Every new experience is an opportunity to learn, to grow, and to become a better version of yourself. Don't be afraid to step out of your comfort zone, for that's where real progress happens.

So stand tall, face your challenges head-on, and don't ever underestimate the power that lies within you. You are capable, you are resilient, and you are destined for greatness. Keep that fire of determination burning brightly, and let it light up your path to success.</h4>
	</div>
	
	<div><br></br></div>
	<div className="card2">
	<h4>Go out there and show the world what you're made of. You've got this!"

Remember, a positive attitude and self-belief can go a long way in helping you navigate through life's ups and downs. Keep pushing forward and never underestimate your own potential.</h4>
	</div>
	
	
	
	</div>

	
);
};

export const OurAim = () => {
return (
	<div className="home">
	<h1>Aim</h1>
	</div>
);
};

export const OurVision = () => {
return (
	<div className="home">
	<h1>Vision</h1>
	</div>
);
};
