import React from "react";

const Support = () => {
return (
	<div className="support">
	<h1>Support us</h1>
	</div>
);
};

export default Support;
