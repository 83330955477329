import React from "react";

export const Events = () => {
return (
	<div className="events">
	<h1> Events</h1>
	</div>
);
};

export const EventsOne = () => {
return (
	<div className="events">
	<h1> Event1</h1>
	</div>
);
};

export const EventsTwo = () => {
return (
	<div className="events">
	<h1> Event2</h1>
	</div>
);
};
