import React from "react";

const Contact = () => {
return (
	<div className="contact">
	<h1>Contact us</h1>
	</div>
);
};

export default Contact;
